import React from "react"
const LeaveBox = ({
    userDetailsData,
    leaveData,
    title,
    isLoading
}: any) => {
    const isLOP = userDetailsData - leaveData
    return (
        <>
            <div className="box">
                <p><strong>{title}</strong></p>
                <p>Total Leaves</p>
                <h4>{!isLoading ? '-' : userDetailsData ?? 0}</h4>
                <p>Approved Leaves</p>
                <h4>{!isLoading ? '-' : leaveData}</h4>
                <p>Remaining Leaves</p>
                <h4>{!isLoading ? '-' : isLOP > 0 ? isLOP : '0'}</h4>
                <p>LOP</p>
                <h4>{!isLoading ? '-' : isLOP > 0 ? 0 : Math.abs(isLOP)}</h4>
            </div>
        </>
    )
}
export default LeaveBox