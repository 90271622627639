import { useState } from "react";

import Accordion from "../Library/Accordion/Accordion";



const Sidebar = () => {
  const [activeTab, setActiveTab] = useState();
  const [customPostTypeCat, setCustomPostTypeCat] = useState([])

  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;
  // @ts-ignore
  const isLOPAssignee = JSON.parse(localStorage.getItem("is_lopAssigned")) || null;
  const NavAdmin = [
    {
      id: 0,
      text: "Users",
      link: "/user-management/view-users",
      icon: 'group',
      isRole: 'admin',
      hasSubMenu: [
        {
          id: 0,
          text: "Add User",
          link: "/user-management/add-users/add",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "View Users",
          link: "/user-management/view-users",
          isRole: 'admin',
        }
      ],
    },

    {
      id: 0,
      text: "Leaves",
      link: "/leaves-management/view-leaves",
      icon: 'calendar_month',
      isRole: 'admin',
      hasSubMenu: [
        {
          id: 0,
          text: "Apply Leave",
          link: "/leaves-management/add-leaves/add",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "My Leaves",
          link: "/leaves-management/view-leaves/users",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "Manage All Leaves",
          link: "/leaves-management/view-leaves/allUsers",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "Add Holiday",
          link: "/events-management/add-rooms/add",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "View Holiday",
          link: "/events-management/view-rooms",
          isRole: 'admin',
        }
      ],
    },

    {
      id: 0,
      text: "Discussion Forum",
      link: "/posts-management/view-posts",
      isRole: 'admin',
      icon: 'forum',
      hasSubMenu: [
        {
          id: 0,
          text: "Add Post",
          link: "/posts-management/add-posts/add",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "My Posts",
          link: "/posts-management/my-posts/my-posts",
          isRole: 'admin',
        }, {
          id: 0,
          text: "Manage All posts",
          link: "/posts-management/view-posts/blog",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "View Posts",
          link: "/posts-management/view-all-posts",
          isRole: 'admin',
        },

        {
          id: 0,
          text: "Add Category",
          link: "/custom-post-type/add-custom-category/add",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "View Category",
          link: "/custom-post-type/view-post-category",
          isRole: 'admin',
        }
      ]
    },
    {
      id: 0,
      text: "Conference Rooms",
      link: "/conference-rooms-management/view-conference-rooms",
      isRole: 'admin',
      icon: 'contacts',
      hasSubMenu: [
        {
          id: 0,
          text: "Book Conference Room",
          link: "/conference-rooms-management/view-conference-rooms/add-booking/add",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "My Booking",
          link: "/conference-rooms-management/view-conference-rooms/my-bookings",
          isRole: 'admin',
        }, {
          id: 0,
          text: "All Booking",
          link: "/conference-rooms-management/view-conference-rooms/manage-conference-bookings",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "Add Conference Room",
          link: "/conference-rooms-management/add-rooms/add",
          isRole: 'admin',
        },
        {
          id: 0,
          text: "View Conference Room",
          link: "/conference-rooms-management/view-rooms",
          isRole: 'admin',
        }

      ],
    },
    {
      id: 0,
      text: "My Profile",
      link: "/leaves-management/view-leaves",
      isRole: 'admin',
      icon: 'person',
      hasSubMenu: [
        {
          id: 0,
          text: "Update Profile",
          link: `/user-management/edit-user/edit/${profile_details?._id}`,
          isRole: 'admin',
        },
        {
          id: 0,
          text: "Change Password",
          link: `/user-management/change-password/edit/${profile_details?._id}`,
          isRole: 'admin',
        }
      ],
    },
    {
      id: 0,
      text: "Firm Document",
      link: "/media-management/view-media",
      isRole: 'admin',
      icon: 'photo_library',
      hasSubMenu: [
        {
          id: 0,
          text: "View Documents",
          link: `/media-management/view-media`,
          isRole: 'admin',
        },
        {
          id: 0,
          text: "Upload Documents",
          link: `/media-management/add-media`,
          isRole: 'admin',
        },
      ],
    },

    {
      id: 0,
      text: "Comments and Likes",
      link: "/leaves-management/view-leaves",
      isRole: 'admin',
      icon: 'rate_review',
      hasSubMenu: [
        {
          id: 0,
          text: "My Comments",
          link: `/comments-management/${profile_details?._id}`,
          isRole: 'admin',
        },
        {
          id: 0,
          text: "My Likes",
          link: `/likes-management/${profile_details?._id}`,
          isRole: 'admin',
        },

        {
          id: 0,
          text: "All Comments",
          link: `/comments-management/All`,
          isRole: 'admin',
        },
        {
          id: 0,
          text: "All Likes",
          link: `/likes-management/All`,
          isRole: 'admin',
        }
      ],
    },

    {
      id: 0,
      text: "Emails",
      link: "/emails-management/view-emails",
      isRole: 'admin',
      icon: 'mail',
      hasSubMenu: [
        {
          id: 0,
          text: "View Emails",
          link: `/emails-management/view-emails`,
          isRole: 'admin',
        },
        {
          id: 0,
          text: "Send Email",
          link: `/emails-management/send-emails`,
          isRole: 'admin',
        }]
    },
    {
      id: 0,
      text: "Settings",
      link: "/emails-management/view-emails",
      isRole: 'admin',
      icon: 'mail',
      hasSubMenu: [
        {
          id: 0,
          text: "LOP Email Setting",
          link: `/settings-management/lop-email-setting-emails`,
          isRole: 'admin',
        }
      ]
    },

    // {
    //   id: 0,
    //   text: "Settings",
    //   link: "/backup-management/download-backup",
    //   isRole: 'admin',
    //   icon: 'mail',
    //   hasSubMenu: [
    //     {
    //       id: 0,
    //       text: "Backup",
    //       link: `/backup-management/download-backup`,
    //       isRole: 'admin',
    //     }]
    // },
  ]


  const NavProfessional = [

    {
      id: 0,
      text: "Leaves",
      link: "/leaves-management/view-leaves",
      icon: 'calendar_month',
      isRole: 'professional',
      hasSubMenu: [
        {
          id: 0,
          text: "Apply Leave",
          link: "/leaves-management/add-leaves/add",
          isRole: 'professional',
        },
        {
          id: 0,
          text: "My Leaves",
          link: "/leaves-management/view-leaves/users",
          isRole: 'professional',
        },
        {
          id: 0,
          text: "View Holiday",
          link: "/events-management/view-rooms",
          isRole: 'professional',
        }
      ],
    },
    {
      id: 0,
      text: "Discussion Forum",
      link: "/posts-management/view-posts",
      isRole: 'professional',
      icon: 'forum',
      hasSubMenu: [
        {
          id: 0,
          text: "Add Post",
          link: "/posts-management/add-posts/add",
          isRole: 'professional',
        },
        {
          id: 0,
          text: "My posts",
          link: "/posts-management/my-posts/my-posts",
          isRole: 'professional',
        },
        {
          id: 0,
          text: "View Posts",
          link: "/posts-management/view-all-posts",
          isRole: 'professional',
        }
      ]
    },
    {
      id: 0,
      text: "Conference Rooms",
      link: "/conference-rooms-management/view-conference-rooms",
      isRole: 'professional',
      icon: 'contacts',
      hasSubMenu: [
        {
          id: 0,
          text: "Book Conference Room",
          link: "/conference-rooms-management/view-conference-rooms/add-booking/add",
          isRole: 'professional',
        },
        {
          id: 0,
          text: "My Booking",
          link: "/conference-rooms-management/view-conference-rooms/my-bookings",
          isRole: 'professional',
        }, {
          id: 0,
          text: "All Booking",
          link: "/conference-rooms-management/view-conference-rooms/manage-conference-bookings",
          isRole: 'professional',
        }
      ],
    },
    {
      id: 0,
      text: "My Profile",
      link: "/leaves-management/view-leaves",
      isRole: 'professional',
      icon: 'person',
      hasSubMenu: [
        {
          id: 0,
          text: "Update Profile",
          link: `/user-management/edit-user/edit/${profile_details?._id}`,
          isRole: 'professional',
        },
        {
          id: 0,
          text: "Change Password",
          link: `/user-management/change-password/edit/${profile_details?._id}`,
          isRole: 'professional',
        }
      ],
    },
    {
      id: 0,
      text: "Firm Document",
      link: "/media-management/view-media",
      isRole: 'admin',
      icon: 'photo_library',
      hasSubMenu: [
        {
          id: 0,
          text: "View Documents",
          link: `/media-management/view-media`,
          isRole: 'admin',
        }
      ],
    },
    {
      id: 0,
      text: "Comments and Likes",
      link: "/leaves-management/view-leaves",
      icon: 'rate_review',
      isRole: 'professional',
      hasSubMenu: [
        {
          id: 0,
          text: "My Comments",
          link: `/comments-management/${profile_details?._id}`,
          isRole: 'professional',
        },
        {
          id: 0,
          text: "My Likes",
          link: `/likes-management/${profile_details?._id}`,
          isRole: 'professional',
        }
      ],
    },
  ]

  const NavLeaveManager = [
    {
      id: 0,
      text: "Leaves",
      link: "/leaves-management/view-leaves",
      isRole: 'leave-manager',
      icon: 'calendar_month',
      hasSubMenu: [
        {
          id: 0,
          text: "Apply Leave",
          link: "/leaves-management/add-leaves/add",
          isRole: 'leave-manager',
        },
        {
          id: 0,
          text: "My Leaves",
          link: "/leaves-management/view-leaves/users",
          isRole: 'leave-manager',
        },
        {
          id: 0,
          text: "Manage All Leaves",
          link: "/leaves-management/view-leaves/allUsers",
          isRole: 'leave-manager',
        },
        {
          id: 0,
          text: "View Holiday",
          link: "/events-management/view-rooms",
          isRole: 'leave-manager',
        }
      ],
    },

    {
      id: 0,
      text: "Discussion Forum",
      link: "/posts-management/view-posts",
      isRole: 'leave-manager',
      icon: 'forum',
      hasSubMenu: [
        {
          id: 0,
          text: "Add Post",
          link: "/posts-management/add-posts/add",
          isRole: 'leave-manager',
        },
        {
          id: 0,
          text: "My Posts",
          link: "/posts-management/my-posts/my-posts",
          isRole: 'leave-manager',
        },
        {
          id: 0,
          text: "View Posts",
          link: "/posts-management/view-all-posts",
          isRole: 'leave-manager',
        }
      ]
    },
    {
      id: 0,
      text: "Conference Rooms",
      link: "/conference-rooms-management/view-conference-rooms",
      isRole: 'leave-manager',
      icon: 'contacts',
      hasSubMenu: [
        {
          id: 0,
          text: "Book Conference Room",
          link: "/conference-rooms-management/view-conference-rooms/add-booking/add",
          isRole: 'leave-manager',
        },
        {
          id: 0,
          text: "My Booking",
          link: "/conference-rooms-management/view-conference-rooms/my-bookings",
          isRole: 'leave-manager',
        }, {
          id: 0,
          text: "All Booking",
          link: "/conference-rooms-management/view-conference-rooms/manage-conference-bookings",
          isRole: 'leave-manager',
        }
      ],
    },
    {
      id: 0,
      text: "My Profile",
      link: "/leaves-management/view-leaves",
      isRole: 'leave-manager',
      icon: 'person',
      hasSubMenu: [
        {
          id: 0,
          text: "Update Profile",
          link: `/user-management/edit-user/edit/${profile_details?._id}`,
          isRole: 'leave-manager',
        },
        {
          id: 0,
          text: "Change Password",
          link: `/user-management/change-password/edit/${profile_details?._id}`,
          isRole: 'leave-manager',
        }
      ],
    },
    {
      id: 0,
      text: "Firm Document",
      link: "/media-management/view-media",
      isRole: 'admin',
      icon: 'photo_library',
      hasSubMenu: [
        {
          id: 0,
          text: "View Documents",
          link: `/media-management/view-media`,
          isRole: 'admin',
        }
      ],
    },
    {
      id: 0,
      text: "Comments and Likes",
      link: "/leaves-management/view-leaves",
      isRole: 'leave-manager',
      icon: 'rate_review',
      hasSubMenu: [
        {
          id: 0,
          text: "My Comments",
          link: `/comments-management/${profile_details?._id}`,
          isRole: 'leave-manager',
        },
        {
          id: 0,
          text: "My Likes",
          link: `/likes-management/${profile_details?._id}`,
          isRole: 'leave-manager',
        }
      ],
    },
  ]

  console.log("isLOPAssignee", isLOPAssignee?.value?.length > 0)

  return (
    <nav className="sidebar">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        {profile_details?.user_type === 'admin' ?
          <Accordion
            activeTab={setActiveTab}
            item={NavAdmin}
          ></Accordion>
          :
          isLOPAssignee?.value?.length > 0 ? <Accordion
            activeTab={setActiveTab}
            item={NavLeaveManager}
          ></Accordion>
            :
            profile_details?.user_type === 'employee' ?
            <Accordion
              activeTab={setActiveTab}
              item={NavProfessional}
            ></Accordion>
            :
            profile_details?.user_type === 'professional' ?
              <Accordion
                activeTab={setActiveTab}
                item={NavProfessional}
              ></Accordion>
              :
              profile_details?.designation === 'CEO' ?
                <Accordion
                  activeTab={setActiveTab}
                  item={NavLeaveManager}
                ></Accordion> :
                <Accordion
                  activeTab={setActiveTab}
                  item={NavLeaveManager}
                ></Accordion>
        }
      </ul>
    </nav>
  );
};

export default Sidebar;
