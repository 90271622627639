/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button, Select, DatePickerComp } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { RegisterUser, UserById, RegisterUserUpdate, AssignManager, getMasterLeave, LOPAssignManager } from "../../servies/services";
import MediaPopup from "../Media/MediaPopup";
import Managers from './Managers'
import CustomTooltip from "../../Library/Tooltip/Tippy";
import { objectToQueryString } from "../../Library/Utility/Utility";
import moment from "moment";
import LOPManagers from "./LOPManagers";
import LeaveBox from "../Leaves/LeaveBox";

type formDataProps = {
  email_id: string;
  first_name: string;
  last_name?: string;
  mobile?: string;
  password?: string;
  status?: string;
  user_type?: string;
  _id?: string;
  leaves?: string;
  designation?: string;
  image?: string;
  managers?: any
  bio?: any
  interests?: any
  dob?: any
  leaves2?: any
  leaves3?: any
};

const statusOption = [
  { text: "Active", id: 1, value: "1" },
  { text: "Inactive", id: 2, value: "0" },
];

export const userOption = [
  { text: "Admin", id: 1, value: "admin" },
  { text: "Leave Approver", id: 2, value: "leave-approver" },
  { text: "User", id: 2, value: "professional" },
];

export const teamOptions = [
  { text: "CEO", id: 1, value: "CEO" },
  { text: "Founder & Managing Partner", id: 1, value: "Founder & Managing Partner" },
  { text: "Partner", id: 2, value: "Partner" },
  { text: "Of-Counsel", id: 2, value: "Of-Counsel" },
  { text: "Manager", id: 2, value: "Manager" },
  { text: "HR - Lead", id: 2, value: "HR - Lead" },
  { text: "Senior Associate", id: 2, value: "Senior Associate" },
  { text: "Associate", id: 2, value: "Associate" },
  { text: "Trainee Associate", id: 2, value: "Trainee Associate" },
  { text: "Legal Executive", id: 2, value: "Legal Executive" },
  { text: "Billing and MIS Executive", id: 2, value: "Billing and MIS Executive" },
  { text: "PMO - Manager", id: 2, value: "PMO- Manager" },
  { text: "Manager Accounts", id: 2, value: "Manager Accounts" },
  { text: "Administration Executive", id: 2, value: "Administration Executive" },
  { text: "IT Executive", id: 2, value: "IT Executive" },
  { text: "Court Clerk", id: 2, value: "Court Clerk" },
  { text: "Client Relations Manager", id: 2, value: "  Client Relations Manager" },
  { text: "Manager HR & Admin", id: 2, value: "  Manager HR & Admin" },
  { text: "Administration Manager", id: 2, value: "Administration Manager" },
];

const AddUser = () => {
  const navigate = useNavigate();
  let { id, type } = useParams();

  const [leaveData, setLeaveData] = useState(0)
  const [leaveData2, setLeaveData2] = useState(0)
  const [leaveData3, setLeaveData3] = useState(0)
  const [isLoadingleaveData, setIsLoadingLeaveData] = useState(false)
  const [isLoadingleaveData2, setIsLoadingLeaveData2] = useState(false)
  const [isLoadingleaveData3, setIsLoadingLeaveData3] = useState(false)

  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isLoading, setIsLoading]: any = useState(false);

  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();
  const [formDataManager, setFormDataManager] = useState<any>();

  const [lopFormDataManager, setLopFormDataManager] = useState<any>();

  const [submitType, setSubmitType] = useState(type);
  const [leaveDataLoading, setLeaveDataLoading] = useState(false)

  const [isPaidLeave, setIsPaidLeave] = useState({
    days: 0,
    isNegative: false
  })



  const [serverError, setServerError]: any = useState(
    "Something went wrong, please try again"
  );
  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

  const onChangeSingleCallback = (data: any) => {
    // console.log("data onChangeSingleCallback", data)
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };



  const getUserData = async () => {
    const APIResponse = await UserById(id);
    if (APIResponse?.data?.isSuccess === true) {
      setFormData(APIResponse?.data?.data);
      // console.log("APIResponse?.data?.data", APIResponse?.data?.data?.email_id)
      setIsLoading(true);
    } else {
      toast.error("Something went wrong, please try again");
      setIsError(true);
      setIsLoading(true);
    }
  };



  useEffect(() => {
    setIsLoading(false);

    if (type === "edit" || type === "view") {
      // setSubmitType("edit");

      getUserData();
    } else {
      // setSubmitType("edit");
      setFormData({
        email_id: "",
        first_name: "",
        last_name: "",
        mobile: "",
        password: "",
        status: "",
        user_type: "",
        _id: "",
      });
      setIsLoading(true);
    }
  }, [type]);

  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (
      formData?.email_id !== undefined &&
      formData?.email_id.length !== 0 &&
      formData?.designation !== undefined &&
      formData?.designation.length !== 0 &&
      formData?.user_type !== undefined &&
      formData?.user_type.length !== 0 &&
      formData?.first_name !== undefined &&
      formData?.first_name.length !== 0 &&
      formData?.last_name !== undefined &&
      formData?.last_name.length !== 0 &&
      formData?.leaves !== undefined &&
      formData?.leaves.length !== 0 &&
      formData?.leaves2 !== undefined &&
      formData?.leaves2.length !== 0 &&
      formData?.leaves3 !== undefined &&
      formData?.leaves3.length !== 0
    ) {
      setIsValid(true);
      let postData = {
        email_id: formData?.email_id,
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        mobile: formData?.mobile,
        password: formData?.password,
        user_type: formData?.user_type,
        leaves: formData?.leaves,
        leaves2: formData?.leaves2,
        leaves3: formData?.leaves3,
        designation: formData?.designation,
        image: formData?.image,
        bio: formData?.bio,
        interests: formData?.interests,
        created_by: profile_details?.email_id ?? 'Postman',
        dob: moment(formData?.dob).format("MM/DD/YYYY").toString()
      };
      if (type === "add") {
        let postDataDuplicate = {
          ...postData,
          status: '0',
        }
        const APIResponse = await RegisterUser(postDataDuplicate);
        if (APIResponse?.data?.isSuccess === true) {
          toast.success(APIResponse?.data?.messages);
          redirectToParent();
        } else {
          if (APIResponse?.data?.isSuccess === false) {
            toast.error(APIResponse?.data?.messages);
            setServerError(APIResponse?.data?.messages);
            setIsError(true);
          } else {
            if (APIResponse?.data?.validataion_error) {
              toast.error(APIResponse?.data?.messages);
              setServerError(APIResponse?.data?.messages);
              setIsError(true);
            } else {
              toast.error("Something went wrong, please try again");
              setIsError(true);
            }
          }
        }

      } else {
        delete postData.password;
        let postDataDuplicate = {
          ...postData,
          status: formData?.status,
          id: formData?._id,
        }
        console.log("postDataDuplicate", postDataDuplicate)
        const APIResponse = await RegisterUserUpdate(postDataDuplicate);
        if (APIResponse?.data?.isSuccess === true) {
          toast.success(APIResponse?.data?.messages);
          redirectToParent();
        } else {
          if (APIResponse?.data?.validataion_error) {
            toast.error(APIResponse?.data?.messages);
            setServerError(APIResponse?.data?.messages);
            setIsError(true);
          } else {
            toast.error("Something went wrong, please try again");
            setIsError(true);
          }
        }
      }
      if (profile_details?.user_type === 'admin') {
        console.log("formDataManager", formDataManager)
        const updatedData = {
          ...formDataManager,
          user_email_id: formData?.email_id,
          created_by: profile_details?.email_id ?? 'Postman',
        }
        const APIResponseManager = await AssignManager(updatedData);
        if (APIResponseManager?.data?.isSuccess === true) {
          toast.success("Managers has been assigned successfully.");

          // LOPAssignManager
          console.log("formDataManager", formDataManager)
          const lopUpdatedData = {
            ...lopFormDataManager,
            user_email_id: formData?.email_id,
            created_by: profile_details?.email_id ?? 'Postman',
          }
          const APIResponseManager = await LOPAssignManager(lopUpdatedData);
          if (APIResponseManager?.data?.isSuccess === true) {
            toast.success("LOP Managers has been assigned successfully.");
            redirectToParent();
          } else {
            if (APIResponseManager?.data?.validataion_error) {
              toast.error(APIResponseManager?.data?.messages);
              setServerError(APIResponseManager?.data?.messages);
              setIsError(true);
            } else {
              toast.error("Something went wrong, please try again");
              setIsError(true);
            }
          }
          // LOPAssignManager

          // redirectToParent();
        } else {
          if (APIResponseManager?.data?.validataion_error) {
            toast.error(APIResponseManager?.data?.messages);
            setServerError(APIResponseManager?.data?.messages);
            setIsError(true);
          } else {
            toast.error("Something went wrong, please try again");
            setIsError(true);
          }
        }
      }
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const redirectToParent = () => {
    if (profile_details?.user_type === 'admin') {
      navigate("/user-management/view-users");
    }
  };

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "User Management",
      link: "/user-management/view-users",
    },
    {
      title: "Add User",
      link: "/user-management/add-user/add",
    },
  ];
  const managerData = (data: any) => {
    console.log("setFormDataManager", data)
    setFormDataManager(data)
  }

  const lopManagerData = (data: any) => {
    console.log("setLopFormDataManager", data)
    setLopFormDataManager(data)
  }

  // useEffect(() => {
  //   setLeaveDataLoading(false);
  //   setLeaveData(0);
  //   const getData = async () => {
  //     const query = {
  //       "email_id": formData?.email_id,
  //       "status": "Approved"
  //     }
  //     const apidata: any = await getMasterLeave(objectToQueryString(query));
  //     const APIResponse = apidata?.data?.data;
  //     let valueInBeginning = 0;
  //     let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
  //     setLeaveData(totalExpenditure);
  //     setLeaveDataLoading(true);
  //   };
  //   if (type !== 'add') {
  //     getData();
  //   } else {
  //     setLeaveDataLoading(true);
  //   }
  // }, [formData?.email_id, type])



  useEffect(() => {
    if (formData?._id) {
      setLeaveData(0);
      setIsLoadingLeaveData(false)
      const getData = async () => {
        const query = {
          "email_id": formData?.email_id,
          "status": "Approved",
          "halfYear": '2',
          "year": 2023
        }
        const apidata: any = await getMasterLeave(objectToQueryString(query));
        const APIResponse = apidata?.data?.data;
        let valueInBeginning = 0;
        let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
        setLeaveData(totalExpenditure);
        setIsLoadingLeaveData(true)
      };
      getData();
    }
  }, [formData?._id, formData?.email_id])


  useEffect(() => {
    if (formData?._id) {
      setLeaveData2(0);
      setIsLoadingLeaveData2(false)
      const getData = async () => {
        const query = {
          "email_id": formData?.email_id,
          "status": "Approved",
          "halfYear": "1",
          "year": 2024
        }
        const apidata: any = await getMasterLeave(objectToQueryString(query));
        const APIResponse = apidata?.data?.data;
        let valueInBeginning = 0;
        let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
        setLeaveData2(totalExpenditure);
        setIsLoadingLeaveData2(true)
      };
      getData();
    }
  }, [formData?._id, formData?.email_id])


  useEffect(() => {
    if (formData?._id) {
      setLeaveData3(0);
      setIsLoadingLeaveData3(false)
      const getData = async () => {
        const query = {
          "email_id": formData?.email_id,
          "status": "Approved",
          "halfYear": "2",
          "year": 2024
        }
        const apidata: any = await getMasterLeave(objectToQueryString(query));
        const APIResponse = apidata?.data?.data;
        let valueInBeginning = 0;
        let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
        setLeaveData3(totalExpenditure);
        setIsLoadingLeaveData3(true)
      };
      getData();
    }
  }, [formData?._id, formData?.email_id])

  useEffect(() => {
    const leave = profile_details?.leaves - leaveData
    setIsPaidLeave({
      days: leave,
      isNegative: leave >= 0
    })
  }, [leaveData, profile_details?.leaves])

  return (
    <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
      <Card title="User Management">
        <p className="text-left mb-4"> You can manage the User data in this module.</p>
        <div className="">
          {isError && (
            <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
          )}
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}

          {isLoading && (
            <div className="row">
              <div className="col-md-8">
                <Input
                  inputName="email_id"
                  inputType="email"
                  labelName={"Email ID"}
                  placeholder="Enter email id"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  disabled={type === "view"}
                  value={formData?.email_id}
                  readonly={type === "edit"}
                />

                <Input
                  inputName="first_name"
                  inputType="text"
                  required={true}
                  labelName={"First Name"}
                  placeholder="Enter first name"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.first_name}
                  disabled={type === "view"}
                />

                <Input
                  inputName="last_name"
                  inputType="text"
                  required={true}
                  labelName={"Last Name"}
                  placeholder="Enter last name"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.last_name}
                  disabled={type === "view"}
                />

                <Input
                  inputName="mobile"
                  inputType="number"
                  labelName={"Mobile"}
                  placeholder="Enter mobile"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.mobile}
                  disabled={type === "view"}
                />

                <Input
                  inputName="leaves3"
                  inputType="number"
                  labelName={"Leaves From July to Dec 2024"}
                  placeholder="Enter leaves"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  required
                  value={formData?.leaves3}
                  disabled={type === "view" || profile_details?.user_type !== 'admin'}
                />

                <Input
                  inputName="leaves"
                  inputType="number"
                  labelName={"Leaves From Jan to June 2024"}
                  placeholder="Enter leaves"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  required
                  value={formData?.leaves}
                  disabled={type === "view" || profile_details?.user_type !== 'admin'}
                />

                <Input
                  inputName="leaves2"
                  inputType="number"
                  labelName={"Leaves From July to Dec 2023"}
                  placeholder="Enter leaves"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  required
                  value={formData?.leaves2}
                  disabled={type === "view" || profile_details?.user_type !== 'admin'}
                />

                <Select
                  isLoading={true}
                  inputName="designation"
                  labelName="Designation"
                  options={teamOptions}
                  onChangeSingleCallback={onChangeSingleCallback}
                  selectedItem={teamOptions.find(
                    (item) => item.value === formData?.designation
                  )}
                  required={true}
                  placeholder="Select designation"
                  search_option={false}
                  disabled={type === "view" || profile_details?.user_type !== 'admin'}
                ></Select>

                <Input
                  inputName="bio"
                  inputType="textarea"
                  labelName={"Bio"}
                  placeholder="Enter bio"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.bio}
                  disabled={type === "view"}
                />

                <Input
                  inputName="interests"
                  inputType="textarea"
                  labelName={"Interests/Hobbies"}
                  placeholder="Enter interests/hobbies"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.interests}
                  disabled={type === "view"}
                />
              </div>
              <div className="col-md-4">
                <div className="row align-content-end justify-content-end">
                  <div className="col-sm-12 text-left">
                    <p className="m-0"><label> Profile Photo</label></p>
                    <MediaPopup
                      onSelectChange={onChangeSingleCallback}
                      disabled={type === "view"}
                      title={formData?.image &&
                        formData?.image !== null && formData?.image !== "null"
                        ? "Change Profile Photo"
                        : "Add Profile Photo"
                      }
                      inputName="image"
                    />
                    {formData?.image && formData?.image.length > 0 && <div className="imagePlaceholderImg"><img src={process.env.react_app_base_url + `/${formData?.image}`} alt="" title="" /></div>}

                  </div>

                  <DatePickerComp
                    inputName="dob"
                    labelName={"DOJ"}
                    placeholder="Select Date of Birth"
                    required={true}
                    disabled={type === "view"}
                    maxDate={new Date()}
                    onChangeSingleCallback={onChangeSingleCallback}
                    value={formData?.dob}
                    disabledWeekend={false}
                    col="12"></DatePickerComp>


                  {/* <Select
                    inputName="status"
                    labelName="Status"
                    options={statusOption}
                    onChangeSingleCallback={onChangeSingleCallback}
                    selectedItem={statusOption.find(
                      (item) => item.value === formData?.status
                    )}
                    required={true}
                    placeholder="Select Status"
                    search_option={false}
                    disabled={type === "view"}
                    isLoading={true}
                  ></Select> */}

                  <Select
                    inputName="user_type"
                    labelName="User Type"
                    options={userOption}
                    onChangeSingleCallback={onChangeSingleCallback}
                    selectedItem={userOption.find(
                      (item) => item.value === formData?.user_type
                    )}
                    required={true}
                    placeholder="Select User Type"
                    search_option={false}
                    disabled={type === "view" || profile_details?.user_type !== 'admin'}
                    isLoading={true}
                  ></Select>

                  <Managers type={type} label={"Leave Managers"} userEmailId={formData?.email_id} dataCallback={managerData}></Managers>
                  {profile_details?.user_type === 'admin' &&
                    <LOPManagers type={type} label={"LOP Manager"} userEmailId={formData?.email_id} dataCallback={lopManagerData}></LOPManagers>
                  }

                  {formData?._id &&
                    <>
                      <div className="col-sm-12 text-left">
                        <LeaveBox isLoading={isLoadingleaveData3} userDetailsData={formData?.leaves3} title={'From July - Dec 2024'} leaveData={leaveData3}></LeaveBox>
                        <LeaveBox isLoading={isLoadingleaveData2}  userDetailsData={formData?.leaves} title={'From Jan - June 2024'} leaveData={leaveData2}></LeaveBox>
                        <LeaveBox isLoading={isLoadingleaveData}  userDetailsData={formData?.leaves2} title={'From July - Dec 2023'} leaveData={leaveData}></LeaveBox>
                      </div>
                    </>
                  }

                  {/*   <div className="col-sm-12">
                    {type !== 'add' && leaveDataLoading === true &&
                      <div className="box">
                        <CustomTooltip
                          title={"title"}
                          position={"bottom"}
                          disabled={false}
                          content={<div className="tooltipContent">
                            <p>Total Leaves</p>
                            <h4>{formData?.leaves}</h4>
                            <p>Approved Leaves</p>
                            <h4>{leaveData}</h4>
                          </div>}>
                          <div>
                            <p>Remaining Leaves</p>
                            <h4>{Number(formData?.leaves) - leaveData > 0 ? Number(formData?.leaves) - leaveData : '0'}</h4>
                          </div>
                        </CustomTooltip>
                      </div>
                    }

                    {type !== 'add' && !isPaidLeave?.isNegative && leaveData !== 0 && leaveDataLoading === true &&
                      <div className="box box-red">
                        <CustomTooltip
                          title={"title"}
                          position={"bottom"}
                          disabled={false}
                          content={<div className="tooltipContent">
                            <p>Total Leaves</p>
                            <h4>{formData?.leaves}</h4>
                            <hr />
                            <p>Approved Leaves</p>
                            <h4>{leaveData}</h4>
                            <p>LOP</p>
                            <h4>{Math.abs(leaveData - Number(formData?.leaves))}</h4>
                          </div>}>
                          <div>
                            <p>LOP</p>
                            <h4>{Math.abs(leaveData - Number(formData?.leaves))}</h4>
                          </div>
                        </CustomTooltip>
                      </div>}
                  </div>
                   */}
                  <div className="col-sm-6 text-right">
                    <Button
                      onClick={() => redirectToParent()}
                      buttonText={"Cancel"}
                      buttonStyleType={"primary"}
                    />
                  </div>
                  <div className="col-sm-6 text-right">
                    <Button
                      onClick={() => Submit("")}
                      disabled={type === "view"}
                      buttonText={
                        type === "add" || type === "view"
                          ? "Submit"
                          : "Update"
                      }
                      buttonStyleType={"primary"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
export default AddUser;
