/* eslint-disable no-empty-pattern */
import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import {
  Alert,
  Card,
  Input,
  Button,
  Select,
  CustomTable,
  SideDrawer,
} from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { AddCityService, GetCityService, GetConfRoom, GetMasterBookAppointment, GetMasterBookAppointmentByuser, GetSingleBookAppointment, GetSingleBookAppointmentByuser, GetUsers } from "../../servies/services";
import ConferenceRoomRow from "./ConferenceRoomRow";
import moment from "moment";
import InlineCalendar from '../../Library/InlineCalendar/InlineCalendar'
import { colorCodes, GetTimeFrame, objectToQueryString } from "../../Library/Utility/Utility";
import { LeaveCalenderDownloadKey, MeetingTableDownloadKey } from "../../Functions/Common";
import TableDownload from "../../Library/CustomTable/TableDownload";
import Filters from "../../Library/Filter/Filter";

type formDataProps = {
  title: string;
  status: string;
  image?: string;
};

const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "created_on",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "_id",
      display: "Id",
      sort: true,
      sortType: "number",
    },
    {
      name: "user_name",
      display: "User Name",
      sort: true,
    },
    {
      name: "meeting_room_name",
      display: "Conference Room",
      sort: true,
    },
    {
      name: "start_date",
      display: "Booking Start Date",
      sort: true,
    },

    {
      name: "end_date",
      display: "Booking End Date",
      sort: true,
    },
    {
      name: "meeting_type",
      display: "Meeting Type",
      sort: true,
    },
    {
      name: "created_on",
      display: "Booking Date",
      sort: true,
    },
    {
      name: "Action",
      display: "Action",
      sort: false,
    },
  ],
};

type actionTypeState = {
  id?: any;
  type?: any;
  data?: any;
};

const statusFilterOption = [
  { text: "All", id: 0, value: "All" },
  { text: "Pending", id: 1, value: "Pending" },
  { text: "Approved", id: 2, value: "Approved" },
  { text: "Rejected", id: 3, value: "Rejected" }
]

const mettingFilterOption = [
  { text: "All", id: 0, value: "All" },
  { text: "One Day Meeting", id: 1, value: "One Day Meeting" },
  { text: "Recurring Meeting", id: 2, value: "Recurring Meeting" },
]

const AddConferenceRoom = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  let { myBookings } = useParams();
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [tableDataCalendar, setTableDataCalendar] = useState<any>([]);
  const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();

  const [tableData, setTableData] = useState<any>([]);
  const [showCells, setShowCells] = useState(null);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);
  const [showContent, setShowContent] = useState('calender');
  const [filterParam, setFilterParam] = useState<any>()
  const [userData, setUserData] = useState<any>([]);
  const [meetingRooms, setMeetingRooms] = useState<any>([]);
  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;
  const [actionType, setActionType] = useState<actionTypeState>({});

  const showCellCallback = (data: any) => {
    setShowCells(data);
  };

  useEffect(() => {
    setIsLoading(false);
    setUserData([]);
    const getData = async () => {
      const apidata = await GetUsers();
      const APIResponse = apidata?.data?.data;
      const newData: any = [
        { text: "All", id: 0, value: 'All' }
      ]
      APIResponse?.map((item: any) => {
        console.log("item", item)
        return newData.push(
          { text: item?.first_name + ' ' + item?.last_name, id: 3, value: item?.email_id }
        )
      })
      setUserData(newData);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI]);

  useEffect(() => {
    setIsLoading(false);
    setMeetingRooms([]);
    const getData = async () => {
      const apidata = await GetConfRoom();
      const APIResponse = apidata?.data?.data;
      const newData: any = [
        { text: "All", id: 0, value: 'All' }
      ]
      APIResponse?.map((item: any) => {
        console.log("item", item)
        return newData.push(
          { text: item?.title, id: 3, value: item?.slug }
        )
      })
      setMeetingRooms(newData);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI]);

  const useFilter = useMemo(() => {
    return [
      {
        "type": "dropdown",
        "label": "Type",
        "name": 'meeting_type',
        "placeholder": "Select Type",
        "default": mettingFilterOption?.[0],
        "options": mettingFilterOption
      },
      {
        "type": "dropdown",
        "label": "Meeting Room",
        "name": 'meeting_room_name',
        "placeholder": "Select Meeting Room",
        "default": meetingRooms?.[0],
        "options": meetingRooms
      },
      ...(myBookings === 'my-bookings' ? [] : [
        {
          "type": "dropdown",
          "name": 'email_id',
          "label": "User",
          "placeholder": "Select User",
          "default": userData?.[0],
          "options": userData
        }])
    ]
  }, [meetingRooms, myBookings, userData])

  const filterData = useCallback((data) => {
    console.log("filterData useCallback", data)
    let newObj = {}
    for (const [key, value] of Object.entries(data)) {
      if (value !== 'All') {
        const source = { [key]: value }
        Object.assign(newObj, source)
      }
    }
    setFilterParam(newObj)
  }, [])


  useEffect(() => {
    setIsLoading(false);
    setTableData([]);
    const getData = async () => {
      const query = {
        // sortBy:"meeting_room_name",
        sortBy:'created_on',
        ...(myBookings === 'my-bookings' ? { "user_email_id": profile_details?.email_id } : {}),
        ...filterParam
      }
      const apidata = await GetMasterBookAppointment(objectToQueryString(query));

      const APIResponse = apidata?.data?.data;
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();

    setIsLoading(false);
    setTableDataCalendar([]);
    const getData2 = async () => {
      const query = {
        sortBy:'created_on',
        ...(myBookings === 'my-bookings' ? { "user_email_id": profile_details?.email_id } : {}),
        ...filterParam
      }
      const apidata = await GetSingleBookAppointment(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableDataCalendar(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData2();

    // } 
    // else {
    //   setIsLoading(false);
    //   setTableData([]);
    //   const getData = async () => {
    //     const query = {
    //       sortBy: 'created_on'
    //     }
    //     const apidata = await GetMasterBookAppointment();
    //     const APIResponse = apidata?.data?.data;
    //     setTableData(APIResponse);
    //     setIsLoading(true);
    //     setReloadAPI(false);
    //   };
    //   getData();
    //   setIsLoading(false);
    //   setTableDataCalendar([]);
    //   const getData2 = async () => {
    //     const apidata = await GetSingleBookAppointment();
    //     const APIResponse = apidata?.data?.data;
    //     setTableDataCalendar(APIResponse);
    //     setIsLoading(true);
    //     setReloadAPI(false);
    //   };
    //   getData2();
    // }

  }, [reloadAPI, myBookings, profile_details?.email_id, filterParam]);

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "City Management",
      link: "/dashboard",
    },
    {
      title: "View City",
      link: "/dashboard",
    },
  ];
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  const CloseDrawer = () => {
    setActionType({});
  };

  const callbackFresh = (data: any) => {
    console.log('data', data)
    setActionType({
      type: "add",
      id: 'id',
      data: data
    })

    setTableDataCalendarSingle([]);
    const getData2 = async () => {
      const query = {
        parent_id: [data?.parent_id]
      }
      const apidata = await GetSingleBookAppointment(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableDataCalendarSingle(APIResponse);
    };
    getData2();
  }

  const pageData = 'Conference Rooms'
  const pageDescData = 'You can manage the Conference Rooms data in this module.'



  return (
    <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>

      <div className="pageGropupBtn">
        <Button onClick={() => setShowContent('calender')} className={showContent === 'calender' ? 'active' : 'inactive'} buttonStyleOutline buttonStyleType={showContent === 'calender' ? 'link' : 'link'}>Calender View</Button>
        <Button onClick={() => setShowContent('table')} className={showContent === 'table' ? 'active' : 'inactive'} buttonStyleOutline buttonStyleType={showContent === 'table' ? 'link' : 'link'}>Table View</Button>
      </div>
      <Card>
        <div className="">
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          <div className="row">
            <div className="col-md-12">
              {showContent === 'calender' ?
                <>
                  <div className="row align-content-end justify-content-start">
                    <div className="col-md-5"><h4 className="card-title">{pageData}</h4>
                      <p>{pageDescData}</p></div>
                    <div className="col-md-3">
                      <ul className="colorCodes">
                        {colorCodes?.map((code: any) => {
                          return (
                            <>
                              <li style={{ background: code?.colorCode }}>
                                {code?.name}
                              </li>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex justify-content-end">
                        {useFilter?.length > 0 &&
                          <Filters
                            sidebarFilterData={filterData}
                            filter={useFilter}></Filters>
                        }
                        <TableDownload
                          data={tableDataCalendar}
                          tableTitle={'Day wise leave'}
                          sortingData={tableDataCalendar}
                          disabled={tableDataCalendar.length === 0}
                          downloadKeys={MeetingTableDownloadKey}
                        />
                      </div>
                    </div>
                  </div>
                  <InlineCalendar
                    calendarData={tableDataCalendar}
                  >
                  </InlineCalendar>
                </>
                :
                <CustomTable
                  header={tableConfig.tableHeader}
                  sortDefault={tableConfig.sortDefault}
                  data={tableData}
                  tableTitle={pageData}
                  tableSubTitle={pageDescData}
                  dropdown={""}
                  search_bar={true}
                  showCellCallback={showCellCallback}
                  sortState={sortState}
                  isLoading={isLoading}
                  downloadKeys={MeetingTableDownloadKey}
                  filter={useFilter}
                  sidebarFilterData={filterData}
                >
                  {({ rowData, index }: { rowData: any; index: any }) => (
                    <ConferenceRoomRow
                      rowData={rowData}
                      index={index}
                      showCells={showCells}
                      reloadData={reloadData}
                      callbackId={callbackFresh}
                    ></ConferenceRoomRow>
                  )}
                </CustomTable>
              }
            </div>
          </div>
        </div>

        {(actionType?.type === "add" || actionType?.type === "edit") && (
          <SideDrawer
            size={'850px'}
            pagetitle={`Details for ${actionType?.data?.meeting_title}`}
            action={CloseDrawer}
          >
            <div className="p-1">
              
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th> Title </th>
                    <th> Meeting Room </th>
                    <th> Date </th>
                    <th> Start Time </th>
                    <th> End Time </th>
                  </tr>
                </thead>
                <tbody>
                  {tableDataCalendarSingle?.map((item: any) => {
                    return (<tr>
                      <td> {item?.meeting_title} </td>
                      <td> {item?.meeting_room_name}</td>
                      <td> {item?.date} </td>
                      <td> {GetTimeFrame(item?.start_time)} </td>
                      <td> {GetTimeFrame(item?.end_time)} </td>
                    </tr>)
                  })}
                </tbody>
              </table>
            </div>
            <div className="clearfix"></div>
          </SideDrawer>
        )}
      </Card>
    </Wrapper>
  );
};
export default AddConferenceRoom;
