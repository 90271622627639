import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import './css/bootstrap.css';
import './css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.css';
import { ToastContainer } from 'react-toastify';
import { setUpAxios } from './servies/services';

import Login from './container/Login/Login';
import ForgotPassword from './container/ForgotPassword/ForgotPassword';
import ChangePassword from './container/ChangePassword/ChangePassword';
import Dashboard from './container/Dashboard/Dashboard';

// City Management
import AddConferenceRoom from './container/ConferenceRoom/AddConferenceRoom';

import ViewConferenceRoom from './container/ConferenceRoom/ViewConferenceRoom';

// User Management
import AddUser from './container/UserManagement/AddUser';
import ViewUser from './container/UserManagement/ViewUser';

// Media Management
import AddMedia from './container/Media/AddMedia';
import ViewMedia from './container/Media/ViewMedia';

// Create Post Type Management
import CreatePostType from './container/CMSSetting/PostType/AddPosts';
import ViewCustomPostType from './container/CMSSetting/PostType/ViewPostType';
import UpdatePassword from './container/UserManagement/ChangePassword'
import CreateTypeCategory from './container/CMSSetting/TypeCategory/CreateTypeCategory';
import ViewTypeCategory from './container/CMSSetting/TypeCategory/ViewTypeCategory';

import CreateCMS from './container/CMS/CreateCMS';
import ViewCMS from './container/CMS/ViewCMS';
import ViewPosts from './container/Posts/ViewPosts';
import ViewPostDetails from './container/Posts/ViewPostDetails';

import CreateTypeSubCategory from './container/CMSSetting/TypeSubCategory/CreateTypeSubCategory';
import ViewTypeSubCategory from './container/CMSSetting/TypeSubCategory/ViewTypeSubCategory';

import VerifyAccount from './container/VerifyAccount/VerifyAccount'
import PageNotFound from './container/PageNotFound/PageNotFound';
import AddLeave from './container/Leaves/AddLeave';
import ViewLeaves from './container/Leaves/ViewLeaves';
import ViewComments from './container/Comments/ViewComments';
import ViewLikes from './container/Likes/ViewLikes';

import CreateConferenceRoom from './container/ConferenceRoom/ConferenceSetting/CreateConferenceRoom';
import ViewConferenceRooms from './container/ConferenceRoom/ConferenceSetting/ViewConferenceRooms';

import CreateEvents from './container/Events/CreateEvents';
import ViewEvents from './container/Events/ViewEvents';

import ViewEmail from './container/Emails/ViewEmail'
import CreateEmails from './container/Emails/CreateEmails'
import DBBackup from './container/Backup/DBBackup'
import LOPSettings from './container/Settings/LOP-Settings';


function App() {
  setUpAxios();
  console.log("window.location.pathname setUpAxios", window.location.pathname)
  console.log("window.location.search setUpAxios", window.location.search)
  return (
    <>
      {/* basename={'/tlegalintranet-admin'} */}
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/verify_email" element={<VerifyAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change_password" element={<ChangePassword />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/user-management/change-password/edit/:id" element={<UpdatePassword />} />
          <Route path="/user-management/add-users/:type" element={<AddUser />} />
          <Route path="/user-management/edit-user/:type/:id" element={<AddUser />} />
          <Route path="/user-management/view-users" element={<ViewUser />} />

          <Route path="/leaves-management/add-leaves/:type" element={<AddLeave />} />
          <Route path="/leaves-management/edit-leaves/:type/:id" element={<AddLeave />} />
          {/* <Route path="/leaves-management/view-leaves/manage-all-leaves" element={<ViewConferenceRoom />} /> */}

          <Route path="/leaves-management/view-leaves/:leaveType" element={<ViewLeaves />} />

          <Route path="/conference-rooms-management/view-conference-rooms/add-booking/:type" element={<AddConferenceRoom />} />
          <Route path="/conference-rooms-management/view-conference-rooms/edit-booking/:type/:id" element={<AddConferenceRoom />} />
          <Route path="/conference-rooms-management/view-conference-rooms" element={<ViewConferenceRoom />} />
          <Route path="/conference-rooms-management/view-conference-rooms/:myBookings" element={<ViewConferenceRoom />} />

          <Route path="/media-management/add-media" element={<AddMedia />} />
          <Route path="/media-management/edit-media/:type/:id" element={<AddMedia />} />
          <Route path="/media-management/view-media" element={<ViewMedia />} />
          <Route path="/media-management/view-my-media/:myMedia" element={<ViewMedia />} />

          <Route path="/custom-post-type/add-custom-post/:type" element={<CreatePostType />} />
          <Route path="/custom-post-type/edit-custom-post/:type/:id" element={<CreatePostType />} />
          <Route path="/custom-post-type/view-post-type" element={<ViewCustomPostType />} />

          <Route path="/custom-post-type/add-custom-category/:type" element={<CreateTypeCategory />} />
          <Route path="/custom-post-type/edit-custom-category/:type/:id" element={<CreateTypeCategory />} />
          <Route path="/custom-post-type/view-post-category" element={<ViewTypeCategory />} />

          <Route path="/custom-post-type/add-custom-sub-category/:type" element={<CreateTypeSubCategory />} />
          <Route path="/custom-post-type/edit-custom-sub-category/:type/:id" element={<CreateTypeSubCategory />} />
          <Route path="/custom-post-type/view-post-sub-category" element={<ViewTypeSubCategory />} />

          <Route path="/posts-management/add-posts/:type" element={<CreateCMS />} />
          <Route path="/create-cms/:type/:id" element={<CreateCMS />} />
          <Route path="/posts-management/my-posts/:posts" element={<ViewCMS />} />
          <Route path="/posts-management/view-posts/:posts" element={<ViewCMS />} />
          <Route path="/posts-management/view-all-posts" element={<ViewPosts />} />
          <Route path="/posts-management/by/:byType/:category" element={<ViewPosts />} />
          <Route path="/posts-management/by/:byType/:category/:title" element={<ViewPosts />} />
          <Route path="/posts-management/view-all-posts/:byId" element={<ViewPostDetails />} />

          <Route path="/conference-rooms-management/add-rooms/:type" element={<CreateConferenceRoom />} />
          <Route path="/conference-rooms-management/edit-rooms/:type/:id" element={<CreateConferenceRoom />} />
          <Route path="/conference-rooms-management/view-rooms" element={<ViewConferenceRooms />} />


          <Route path="/events-management/add-rooms/:type" element={<CreateEvents />} />
          <Route path="/events-management/edit-rooms/:type/:id" element={<CreateEvents />} />
          <Route path="/events-management/view-rooms" element={<ViewEvents />} />

          <Route path="/likes-management/:Type" element={<ViewLikes />} />
          <Route path="/comments-management/:Type" element={<ViewComments />} />

          <Route path="/emails-management/view-emails" element={<ViewEmail />} />
          <Route path="/emails-management/send-emails" element={<CreateEmails />} />
          <Route path="/backup-management/download-backup" element={<DBBackup />} />

          <Route path="/settings-management/lop-email-setting-emails" element={<LOPSettings />} />

          

          

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ToastContainer></ToastContainer>
      </BrowserRouter>
    </>
  );
}

export default App